import * as echarts from "echarts";
import { compare } from "@/utils/common";
export default {
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    wrapId: {
      type: String,
      default: "chart",
    },
    chartHeight: {
      type: String,
      default: "800px",
    },
    showChartTitle: {
      type: Boolean,
      default: true,
    },
    showChartToolbox: {
      type: Boolean,
      default: true,
    },
    chartTitle: {
      type: String,
      default: "招商漏斗图",
    },
  },
  data() {
    return {
      funnelChart: null,
      baseColors: [
        "rgba(28,78,234,1)",
        "rgba(223,13,235,1)",
        "rgba(24,247,201,1)",
        "rgba(9,203,147,1)",
        "rgba(209,240,99,1)",
        "rgba(93,117,59,1)",
        "rgba(118,112,99,1)",
        "rgba(37,67,106,1)",
        "rgba(39,24,56,1)",
        "rgba(25,9,14,1)",
        "rgba(209, 57, 57, 1)",
        "rgba(33, 206, 206, 1)",
        "rgba(4, 100, 100, 1)",
        "rgba(6, 44, 70, 1)",
        "rgba(0, 3, 207, 1)",
        "rgba(21, 129, 66, 1)",
      ],
      rgbaColors: [
        ["rgba(28,78,234,0.8)", "rgba(28,78,234,0)"],
        ["rgba(223,13,235,0.8)", "rgba(223,13,235,0)"],
        ["rgba(24,247,201,0.8)", "rgba(24,247,201,0)"],
        ["rgba(9,203,147,0.8)", "rgba(9,203,147,0)"],
        ["rgba(209,240,99,0.8)", "rgba(209,240,99,0)"],
        ["rgba(93,117,59,0.8)", "rgba(93,117,59,0)"],
        ["rgba(118,112,99,0.8)", "rgba(118,112,99,0)"],
        ["rgba(37,67,106,0.8)", "rgba(37,67,106,0)"],
        ["rgba(39,24,56,0.8)", "rgba(39,24,56,0)"],
        ["rgba(25,9,14,0.8)", "rgba(25,9,14,0)"],
        ["rgba(209, 57, 57,0.8)", "rgba(209, 57, 57,0)"],
        ["rgba(33, 206, 206,0.8)", "rgba(33, 206, 206,0)"],
        ["rgba(4, 100, 100,0.8)", "rgba(4, 100, 100,0)"],
        ["rgba(6, 44, 70,0.8)", "rgba(6, 44, 70,0)"],
        ["rgba(0, 3, 207,0.8)", "rgba(0, 3, 207,0)"],
        ["rgba(21, 129, 66,0.8)", "rgba(21, 129, 66,0)"],
      ],
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.funnelChart !== null) {
          this.funnelChart.resize();
        }
      },
    },
    chartData: {
      handler() {
        if (this.chartData.length <= 0) {
          this.renderChart(this.getDefaultData());
        } else {
          this.renderChart(this.chartData);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    initChart(wrapId) {
      let seriesTop = "10%";
      let seriesGap = 10;
      let seriesMinSize = 150;
      let seriesWidth = "60%";
      this.funnelChart = echarts.init(
        document.getElementById(wrapId),
        "light"
      );
      // 指定图表的配置项和数据
      const option = {
        title: this.getTitle(),
        color: [],
        tooltip: {
          show: true,
          trigger: "item",
          formatter: function (d) {
            return `${d.data.stageName} ：${d.data.count} 个`;
          },
        },
        toolbox: this.getToolBox(),
        legend: {
          data: [],
        },
        series: [
          {
            name: "main",
            top: seriesTop,
            type: "funnel",
            gap: seriesGap,
            minSize: seriesMinSize,
            left: "25%",
            width: seriesWidth,
            sort: "none",
            label: {
              show: true,
              position: "inside",
              fontSize: "14",
              formatter: function (d) {
                var ins = d.data.stageName + "{aa|}\n" + d.data.count + "个";
                return ins;
              },
              rich: {
                aa: {
                  padding: [8, 0, 6, 0],
                },
              },
            },
            data: [],
          },
          {
            name: "side",
            top: seriesTop,
            type: "funnel",
            gap: seriesGap,
            minSize: seriesMinSize,
            left: "18%",
            width: seriesWidth,
            sort: "none",
            z: 2,
            label: {
              color: "#333",
              position: "left",
              formatter: function (d) {
                let ins = `{aa| ${d.data.stageName}占比：}\n{bb|${d.data.percentValue} %}`;
                return ins;
              },
              rich: {
                aa: {
                  align: "center",
                  color: "#666",
                  fontSize: "12",
                  lineHeight: "30",
                },
                bb: {
                  align: "center",
                  color: "#333",
                  fontSize: "20",
                },
              },
            },
            data: [],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      this.funnelChart.setOption(option);
      this.funnelChart.on("click", (params) => {
        this.$emit("onChartClick", { ...params, disabled: false });
      });
      window.addEventListener("resize", () => {
        this.funnelChart.resize();
      });
    },
    renderChart(data = []) {
      if (this.funnelChart === null || data.length <= 0) {
        return false;
      }
      let legends = [];
      let sideDatas = [];
      let chartDatas = [];
      let max = 0;
      chartDatas = data.filter((item) => item.stageId !== null);
      max = chartDatas.length * 100;
      chartDatas = chartDatas
        .sort(compare("stageSequence"))
        // 如果是流量池模块,则用总数替换流量池本身的数值
        .map((item, index) => {
          if (item.stageId === 1) {
            item.count = item.total;
          }
          if (!legends.includes(item.stageName)) {
            legends.push(item.stageName);
            sideDatas.push({
              value: max - index * 100,
              name: item.stageName,
              percentValue:
                item.total > 0
                  ? ((item.count / item.total) * 100).toFixed(2)
                  : 0,
              ...item,
              itemStyle: {
                color: {
                  type: "linear",
                  x: 1,
                  y: 0,
                  x2: 0,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: this.rgbaColors[index][0], // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: this.rgbaColors[index][1], // 100% 处的颜色
                    },
                  ],
                  global: false,
                },
                borderWidth: 0,
                opacity: 1,
              },
            });
          }
          return {
            value: max - index * 100,
            name: item.stageName,
            percentValue: ((item.count / item.total) * 100).toFixed(2),
            ...item,
          };
        });
      // this.chartHeight = max + 100 + "px";
      this.funnelChart.setOption({
        color: this.baseColors,
        legend: {
          data: legends,
        },
        series: [
          {
            name: "main",
            data: chartDatas,
          },
          {
            name: "side",
            data: sideDatas,
          },
        ],
      });
    },
    getTitle() {
      if (this.showChartTitle) {
        return {
          text: this.chartTitle,
          subtext: "",
        };
      } else {
        return {};
      }
    },
    getToolBox() {
      if (this.showChartToolbox) {
        return {
          feature: {
            saveAsImage: {},
          },
        };
      } else {
        return {};
      }
    },
    getDefaultData() {
      return [
        {
          stageId: 5,
          stageName: "暂无数据1",
          stageSequence: 1000000,
          count: 0,
          total: 0,
          rate: 0,
          addUp: 0,
        },
        {
          stageId: 4,
          stageName: "暂无数据2",
          stageSequence: 30,
          count: 0,
          total: 0,
          rate: 0,
          addUp: 0,
        },
        {
          stageId: 3,
          stageName: "暂无数据3",
          stageSequence: 20,
          count: 0,
          total: 1,
          rate: 0,
          addUp: 0,
        },
        {
          stageId: 2,
          stageName: "暂无数据4",
          stageSequence: 10,
          count: 0,
          total: 0,
          rate: 0,
          addUp: 0,
        },
        {
          stageId: 1,
          stageName: "暂无数据5",
          stageSequence: 1,
          count: 0,
          total: 0,
          rate: 0,
          addUp: 0,
        },
        {
          stageId: null,
          stageName: "",
          stageSequence: null,
          count: 1,
          total: 1,
          rate: 1,
          addUp: 1,
        },
      ];
    },
  },
}